export class APIURL {

  //master
  public static getConstantsEnum: string = "master_data/get-enum-by-catagory";
  public static getGenreList: string = "master_data/get-genre-list";
  public static getEquipListByCode: string = "master_data/get-equip-list-by-code";
  public static getEquipById: string = "master_data/get-equip-list-by-code";
  public static getFaqsByType: string = 'master_data/get-faqs-by-type';
  public static getServicesLocationList: string = 'master_data/get-ServicesLocation-list';
  public static getServicesList: string = 'master_data/get-Services-list';
  public static getDeliverableList: string = 'master_data/get-deliverable-list';
  public static getLanguagesList: string = 'master_data/get-language-list';

  //auth
  public static requestOTP: string = "auth/requestOTP";
  public static signup: string = "auth/register";
  public static login: string = "auth/login";
  public static logout: string = "auth/logout";

  // auth v2
  public static requestOTP_v2: string = "auth/v2/request-auth-OTP";
  public static login_v2: string = "auth/v2/login";
  public static signup_v2: string = "auth/v2/register";
  public static logout_v2: string = "auth/v2/logout";
  public static refreshToken_v2: string = "auth/v2/refresh";

  // account reset
  public static sendPasswordResetLink: string = "auth/v2/sendPasswordResetLink";
  public static resetPassword: string = "auth/v2/resetPassword";
  public static changePassword: string = "auth/v2/updatePassword";

  // generic
  public static newsletterSignup: string = "subscribe-newsletter";
  public static createPhotographerRequest: string = "post_requirement";
  public static createPhotographerRequest_v3: string = "post_requirement-v3";

  // User Genric
  public static getUserByContact: string = "user/get-user-by-contact";
  public static getUserById: string = "user/get-user-by-id";
  public static getUserByMobile: string = "user/get-user-by-mobile";
  public static saveUserProfileImage: string = "user/save-user-profile-image";
  public static userEmailVerification: string = "user/user-email-verification";
  public static resendEmailVerification: string = "user/resend-user-email-verification-link";
  public static getUserMembershipData: string = "user/get-user-membership-data";

  //photographer Profile
  public static getPhotographerById: string = "photographer-profile/get-photographer-by-id";
  public static savePhotographerInfo: string = "photographer-profile/personal-information";
  public static saveGenreExpertise: string = "photographer-profile/genre-expertise";
  public static saveEquipArsenal: string = "photographer-profile/equipment-arsenal";

  // --------------------------- photeam -------------------------------------------------------------
  public static fetchIncompletePhoteamByUser_id: string = 'photeam/check-incomplete-photeam-by-user_id';
  public static validatePhoteamUsername: string = 'photeam/check-unique-photeam_username';
  public static fetchPhoteamDetailsByPhoteam_id: string = 'photeam/get-photeam-data-by-photeam_id';
  public static fetchPhoteamDetailsByphoteam_username: string = 'photeam/get-photeam-data-by-photeam_username';
  public static fetchPhoteamListByUser: string = 'photeam/get-photeam-list-by-user_id';
  public static fetchPhoteamListCreatedByUser: string = 'photeam/get-photeam-list-created-by-user_id';
  public static deletePhoteamById: string = 'photeam/delete';
  public static getPhoteamTabStatus: string = 'photeam/get-photeam-tab-status';
  public static markPhoteamCompleted: string = 'photeam/make-photeam-completed';

  public static savePhoteamProfileAndBannerImages: string = 'photeam/save-photeam-profile-and-banner';
  public static fetchPhoteamProfileAndBannerImages: string = 'photeam/get-photeam-profile-and-banner';

  // General-tab
  public static fetchPhoteamGeneralData: string = 'photeam/general-tab/get-photeam-general-data';
  public static savePhoteamGeneralData: string = 'photeam/general-tab/save-photeam-general-data';

  // services
  public static fetchPhoteamServicesData: string = 'photeam/services-tab/get-photeam-services-data';
  public static savePhoteamServicesData: string = 'photeam/services-tab/save-photeam-services-data';

  // Package-tab
  public static getPhoteamPackageByPhoteamId_v2: string = 'photeam/package-tab/get-photeam-packages-list-by-photeam_id-v2';
  public static fetchPhoteamPackageDataByPackage_id: string = 'photeam/package-tab/get-photeam-packages-data-by-package_id';
  public static savePhoteamPackageData_v2: string = 'photeam/package-tab/save-photeam-packages-data-v2';
  public static deletePackageById: string = 'photeam/package-tab/delete-photeam-packages-data-by-package_id';
  public static toggleHidePackageById: string = 'photeam/package-tab/hide-photeam-packages-by-package_id';

  // Members-tab
  public static fetchPhoteamMembersData: string = 'photeam/members-tab/get-photeam-members-data';
  public static savePhoteamMembersData: string = 'photeam/members-tab/save-photeam-members-data';
  public static verifyJoinPhoteamInviteLink: string = 'photeam/members-tab/verify-join-photeam-invite-link';
  public static verifyJoinPhoteamRequestLink: string = 'photeam/members-tab/verify-join-photeam-request-link';
  public static sendRequestToJoinPhoteamToAdmin: string = 'photeam/members-tab/send-request-to-join-photeam-to-admin';
  public static unacceptedPhoteamInviteList: string = 'photeam/members-tab/get-unaccepted-photeam-invites-by-user_id';

  // photos-tab
  public static fetchPhoteamGenreImagesByPhoteam_id: string = 'photeam/photos-tab/get-photeam-genre-images-by-photeam_id';
  public static fetchPhoteamGenreImagesByGenreId: string = 'photeam/photos-tab/get-photeam-genre-images-by-genre_id';
  public static savePhoteamGenreImagesToSystem: string = 'photeam/photos-tab/save-photeam-genre-images-to-system';
  public static savePhoteamVideosToSystem: string = 'photeam/photos-tab/save-photeam-videos-to-system';
  public static markPhotosStepCompleted: string = 'photeam/photos-tab/mark-photos-step-completed-photeam_id';
  public static saveVideoLinks: string = 'photeam/photos-tab/save-photeam-video-links';
  public static getVideoListByPhoteam_id: string = 'photeam/photos-tab/get-photeam-video-list';
  public static getYoutubeVideoInfo: string = 'photeam/photos-tab/get-youtube-video-info';
  public static deletePhoteamGenreImagesByFileId: string = 'photeam/photos-tab/delete-photeam-genre-image';
  public static deletePortfolioVideoById: string = 'photeam/photos-tab/delete-photeam-video';
  public static highlightImageById: string = 'photeam/photos-tab/highlight-photeam-genre-image';

  // Faq-tab
  public static fetchPhoteamFaqAnswersData: string = 'photeam/get-photeam-faq-answers-data';
  public static savePhoteamFaqAnswersData: string = 'photeam/save-photeam-faq-answers-data';

  // Bank Account
  public static fetchPhoteamBankAccountData: string = 'photeam/get-photeam-bank-accounts-data';
  public static savePhoteamBankAccountData: string = 'photeam/save-photeam-bank-accounts-data';
  // --------------------------- photeam Ends----------------------------------------------------

  // -------------------------- tools --------------------------------------------------------
  //tools Quotations
  public static fetchQuotationsByUser: string = 'tools/quotation/get-quotation-list-by-user_id';
  public static fetchQuotationsByPhoteam: string = 'tools/quotation/get-quotation-list-by-photeam_id';
  public static fetchQuotationById: string = 'tools/quotation/get-quotation-by-quotation_id';
  public static saveQuotation: string = 'tools/quotation/save-quotation';
  public static deleteQuotation: string = 'tools/quotation/delete-quotation';

  //tools Invoice
  public static fetchInvoicesByUser: string = 'tools/invoice/get-invoice-list-by-user_id';
  public static fetchInvoicesByPhoteam: string = 'tools/invoice/get-invoice-list-by-photeam_id';
  public static fetchInvoiceById: string = 'tools/invoice/get-invoice-by-invoice_id';
  public static saveInvoice: string = 'tools/invoice/save-invoice';
  public static deleteInvoice: string = 'tools/invoice/delete-invoice';

  //tools Billing
  public static fetchBillsByUser: string = 'tools/billing/get-bill-list-by-user_id';
  public static fetchBillsByPhoteamID: string = 'tools/billing/get-bill-list-by-photeam_id';
  public static fetchBillByID: string = 'tools/billing/get-bill-data-by-bill_id';
  public static saveBillData: string = 'tools/billing/save-bill-data';
  public static deleteBill: string = 'tools/billing/delete-bill';
  public static saveBillingTempFiles: string = "tools/billing/save-billing-temp-files";
  public static dowanloadBillingFiles: string = "tools/billing/dowanload-billing-files";

  public static fetchInventorybyPhoteamID: string = 'tools/billing/get-inventory-by-photeam_id';
  public static updateInventorybyPhoteamID: string = 'tools/billing/update-inventory-by-photeam_id';

  // tools Generic Data
  public static fetchToolsTnCbyPhoteamID: string = 'tools/generic-data/get-tools-TnC-by-photeam_id';
  public static updateToolsTnCbyPhoteamID: string = 'tools/generic-data/update-tools-TnC-by-photeam_id';
  public static fetchToolsCustomerDetailsByPhoteamID: string = 'tools/generic-data/get-tools-customer-details-by-photeam_id';
  public static updateToolsCustomerDetailsByPhoteamID: string = 'tools/generic-data/update-tools-customer-details-by-photeam_id';
  public static fetchToolsStudioDetailsById: string = 'tools/generic-data/get-tools-studio-details';
  public static saveToolsStudioDetailsById: string = 'tools/generic-data/save-tools-studio-details';
  // -------------------------- tools Ends--------------------------------------------------------

  //favorites
  public static addPhoteamToFavorite: string = 'photeam/add-photeam-to-fav';
  public static listFavoritePhoteamsByUser: string = 'photeam/get-fav-photeam-list-by-user_id';

  // Review
  public static fetchPhoteamReviewList: string = 'review/get-photeam-reviews-list-by-photeam_id';
  public static fetchPhoteamReviewByUser: string = 'review/get-photeam-review-data-by-photeam_id-and-user_id';
  public static savePhoteamReviewByUser: string = 'review/save-photeam-review-data-by-photeam_id-and-user_id';

  public static upVotePhoteamReviewByReview_id: string = 'review/up-vote-photeam-review';
  public static downVotePhoteamReviewByReview_id: string = 'review/down-vote-photeam-review';

  public static replyReviewByPhoteam: string = 'review/reply-from-photeam-owner';

  public static fetchAggregateRatingByPhoteam: string = 'review/get-photeam-aggregated-ratings';


  // refral
  public static fetchUserReferralData: string = 'get-user-referral-data';


  //third party api urls
  public static fetchBankFromIFSC: string = "fetch-bank-by-ifsc";
  public static fetchAddressFromPIN: string = "fetch-address-by-pincode";

  // blogs
  public static getBlogByUrl: string = "blog/get-blog-by-url";
  public static getBlogList: string = "blog/get-blog-list";


  //search photeam
  public static photeamSearchList: string = "search/search-photeam";
  public static getPhoteamsbyNameOrUsername: string = "photeam/search-photeam-by-name-or-username";

  // KYC
  public static getKycByUserId: string = "payments/kyc/get-kyc-data";
  public static storeKycByUserId: string = "payments/kyc/store-kyc-data";
  public static getBankAccountByUserId: string = "payments/kyc/get-bank_account-data";
  public static storeBankAccountByUserId: string = "payments/kyc/store-bank_account-data";

  // Payments
  public static handleCashfreeReturnURL: string = "cashfree-payments/handle-return-URL";

  // Products
  public static getProductList: string = "products/get-product-list";
  public static getProductListWithCoupons: string = "products/get-product-list-with-coupons";
  public static placeProductsPurchaseOrder: string = "products/payments/place-products-purchase-order";
  public static handleProductsPurchaseReturnURL: string = "products/payments/handle-products-purchase-return-url";
  public static getPhoteamDeliverablesData: string = "products/get-photeam-deliverables-data";

  // coupons
  public static getCouponList: string = "coupons/get-coupon-list";
  public static getCouponListWithProducts: string = "coupons/get-coupon-list-with-products";

  // booking Payments
  public static createBookingPaymentLink: string = "booking/payments/create-platform-booking-payment-link";
  // public static handleReturnURL: string = "booking/payments/handle-return-URL";

  //booking
  public static sendBookingInquiry: string = "booking/send-booking-inquiry";
  public static getBookingsByPhoteam_id: string = "booking/get-booking-by-photeam_id";
  public static getUserBookingsByUser_id: string = "booking/get-user-bookings-by-user_id";
  public static updateBookingInquiryStatus: string = "booking/update-booking-status-by-booking_id";
  public static getBookingOverviewPhoteam_id: string = "booking/get-booking-overview-photeam_id";

  public static createCustomBooking: string = "booking/create-custom-booking";
  public static deleteCustomBooking: string = "booking/delete-custom-booking";

  // file transfer
  public static getPhoteamFileTransferProjectList: string = "file-transfer/get-file-transfer-project-list-by-photeamId";
  public static getFileTransferProjectbyLink: string = "file-transfer/get-file-transfer-project-by-link";

  public static saveFiles: string = "file-transfer/save-files";
  public static updateOrCreateFileTransferProject: string = "file-transfer/updateOrCreate-project";

  public static getUserFileTransferProjectList: string = "file-transfer/get-file-transfer-project-list-by-userId";
  public static downloadAlbumFiles: string = "file-transfer/download-album";
  public static deleteAlbumFiles: string = "file-transfer/delete-album";

  public static tempBookingInquiry: string = "booking/store-booking-enq";

  // Notifications
  public static getNotificationsByUser_id: string = "notifications/get-notifications-by-user_id";
  public static markNotificationsReadedByUser_id: string = "notifications/mark-notifications-readed-by-user_id";
  public static updateNotificationStatusByNotification_id: string = "notifications/update-notification-status-by-Notification_id";

  public static telegramBotMsg: string = "notifications/telegram-bot-msg";

  // ---------------------------


  public static superhighlightImageById: string = 'photeam/photos-tab/super-highlight-photeam-genre-image';

  public static savePhoteamGenreImagesToDB: string = 'photeam/save-photeam-genre-images-to-DB';
  public static savePhoteamPackageData: string = 'photeam/save-photeam-packages-data';
  public static getPhoteamPackageByPhoteamId: string = 'photeam/get-photeam-packages-list-by-photeam_id';

}
