export class GlobalConstants {
  // ----------- Local Host ----------------
  // public static baseURL: string = "http://127.0.0.1:8000/api";
  // public static storageURL: string = "http://127.0.0.1:8000/uploads";
  // public static appURL: string = "http://localhost:4200";

  public static DO_ACCESS_KEY_ID: string = "NZ64RBGY25XYS3FS6ASZ";
  public static DO_SECRET_ACCESS_KEY: string = "SA6SkX0xY8FtDuoXJJvTWsg+Edk48fut6q3J/B910UA";
  public static DO_DEFAULT_REGION: string = "nyc3";
  public static DO_BUCKET: string = "meisabikumarspace";
  public static DO_CDN_URL: string = "https://meisabikumarspace.nyc3.cdn.digitaloceanspaces.com";
  public static DO_URL: string = "https://meisabikumarspace.nyc3.digitaloceanspaces.com";
  public static DO_ENDPOINT: string = "https://nyc3.digitaloceanspaces.com";
  // ----------- Local Host ----------------

  // --------- Live Server--------
  public static baseURL: string = "https://pickleit.in/pickle_server/public/api";
  public static storageURL: string = "https://pickleit.in/pickle_server/public/uploads";
  public static appURL: string = "https://pickleit.in";
  // -------------------------------

  // ------- Test server --------------
  // public static baseURL: string = "https://picklelabs.in/pickle_server/public/api";
  // public static storageURL: string = "https://picklelabs.in/pickle_server/public/uploads";
  // public static appURL: string = "https://picklelabs.in";
  // ------------------------------------
}
