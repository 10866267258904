<div>
  <div class="container p-0">
    <div style="max-height: 70vh; overflow: scroll" class="custom-scroll">
      <div class="bill_pdf border" id="element-to-preview">
        <!-- header -->
        <div class="border-black header-wrapper p-2">
          <div class="header_details">
            <div class="d-flex justify-content-between">
              <div class="tw-max-w-[350px]">
                <div class="py-2 ph3 semibold">
                  {{ bill_data?.source_details?.src_name }}
                </div>

                <div class="py-1 ph6">
                  {{ bill_data?.source_details?.src_address }}
                </div>

                <div class="py-1 ph6">
                  <span
                    class="semibold"
                    *ngIf="bill_data?.source_details?.src_mobile"
                    >Mobile:</span
                  >
                  <span> {{ bill_data?.source_details?.src_mobile }}</span>
                  <br />
                  <span
                    class="semibold"
                    *ngIf="bill_data?.source_details?.src_email"
                    >Email:</span
                  >
                  <span> {{ bill_data?.source_details?.src_email }}</span>
                  <br />
                  <!-- <span class="semibold"> Web : </span> <span>www.brdgtal.com</span> -->
                </div>
              </div>

              <div>
                <div class="py-2 ph6 text-right">
                  <span class="semibold">Bill #: </span>
                  <span>{{ bill_data?.basic?.serial_no }}</span>
                </div>
                <div class="logo-wrapper" *ngIf="bill_data?.basic?.logo">
                  <img
                    class="responsive-image"
                    [src]="bill_data?.basic?.logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- customer & bill no -->
        <div class="flex_between border-black bill-wrapper px-2 mt-2 ph6">
          <div class="customer_details">
            <div class="py-1">
              <span class="semibold">Name: </span>
              <span>{{ bill_data?.client_details?.clt_name | titlecase }}</span>
            </div>
            <div class="py-1">
              <span class="semibold">Mobile: </span>
              <span>{{ bill_data?.client_details?.clt_mobile }}</span>
            </div>
            <!-- <div class="py-1" *ngIf="bill_data?.client_details?.clt_email">
              <span class="semibold">Email: </span>
              <span>{{ bill_data?.client_details?.clt_email }}</span>
            </div> -->
          </div>
          <div class="bill_details text-right">
            <div class="py-1">
              <span class="semibold">Bill Date: </span>
              <span>{{ bill_data?.basic?.date | date }}</span>
            </div>
            <div class="py-1" *ngIf="bill_data?.basic?.delivery_date">
              <span class="semibold">Delivery Date: </span>
              <span>{{ bill_data?.basic?.delivery_date | date }}</span>
            </div>
          </div>
        </div>

        <!--  items -->
        <div class="invoice-items-table-wrapper mt-2">
          <table
            class="invoice-table invoice-items-table w-100 border-black ph7"
            cellpadding="0"
            cellspacing="0"
          >
            <thead>
              <tr class="aside-collpased">
                <th width="10" aria-label="Item Number">No.</th>
                <th class="text-left">Item</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Amount</th>
              </tr>
            </thead>

            <tbody class="">
              <ng-container
                *ngFor="let item of bill_data?.item_dtls?.data; index as i"
              >
                <tr height="10">
                  <td class="text-left" height="10">{{ i + 1 }}.</td>
                  <td class="text-left bold" width="250" height="10">
                    {{ item?.item_code }} - {{ item?.item_name | titlecase }}
                  </td>
                  <td class="" width="10" height="10">
                    {{ item?.item_qnt }}
                  </td>
                  <td class="" width="10" height="10">
                    ₹{{ item?.item_rate?.toLocaleString("en-IN") }}
                  </td>

                  <td class="" width="10" height="10">
                    ₹{{ item?.item_cost?.toLocaleString("en-IN") }}
                  </td>
                </tr>
              </ng-container>
              <tr style="height: 100%">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="d-flex justify-content-between">
          <!-- terms & conditions -->
          <div style="max-width: 320px">
            <div class="mt-3" *ngIf="bill_data?.tnc?.length > 0">
              <div class="ph7-semibold">Terms & Conditions</div>
              <ul class="pl-4">
                <li class="ph7" *ngFor="let item of bill_data?.tnc">
                  {{ item?.text }}
                </li>
              </ul>
            </div>
          </div>

          <!-- sub total calculation -->
          <div class="invoice-total-calculation">
            <table
              border="0"
              class="invoice-table invoice-totals-table mt-3 ml-auto ph7"
            >
              <tbody>
                <tr *ngIf="bill_data?.cost?.item_total">
                  <th>Sub Total</th>
                  <td>
                    ₹{{ bill_data?.cost?.item_total?.toLocaleString("en-IN") }}
                  </td>
                </tr>
                <tr *ngIf="bill_data?.cost?.discount_value">
                  <th>Discount ({{ bill_data?.cost?.discount_percent }}%)</th>
                  <td>
                    - ₹{{
                      bill_data?.cost?.discount_value?.toLocaleString("en-IN")
                    }}
                  </td>
                </tr>
                <tr *ngIf="bill_data?.cost?.gst_value">
                  <th>GST ({{ bill_data?.cost?.gst_percent }}%)</th>
                  <td>
                    ₹{{ bill_data?.cost?.gst_value?.toLocaleString("en-IN") }}
                  </td>
                </tr>

                <tr *ngIf="bill_data?.cost?.total">
                  <th>Total</th>
                  <td>
                    <b
                      >₹{{ bill_data?.cost?.total?.toLocaleString("en-IN") }}</b
                    >
                  </td>
                </tr>

                <tr *ngIf="bill_data?.cost?.amount_received">
                  <th>Paid</th>
                  <td>
                    <b
                      >₹{{
                        bill_data?.cost?.amount_received.toLocaleString("en-IN")
                      }}</b
                    >
                  </td>
                </tr>

                <tr *ngIf="bill_data?.cost?.remaining_balance">
                  <th>Balance</th>
                  <td>
                    <b
                      >₹{{
                        bill_data?.cost?.remaining_balance.toLocaleString(
                          "en-IN"
                        )
                      }}</b
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="px-3 tw-p-8" *ngIf="bill_data?.billing_files?.files?.length > 0">
        <div>
          <div class="ph6-semibold ptc5">Attached Images</div>
          <hr class="horizontalLine" />
        </div>
        <div
          class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-3"
        >
          <div
            class="img_grid border rounded"
            *ngFor="let item of bill_data.billing_files.files"
          >
            <img
              [src]="DO_CDN_URL + '/' + item.file_path"
              class="img-fluid img_item tw-object-cover"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex_center py-2 mt-3 justify-content-around">
      <div class="btn-group me-3">
        <button type="button" class="btn secondary_btn" (click)="closeModal()">
          <span class="ph7-semibold"> Close</span>
        </button>
        <div
          class="btn-group"
          ngbDropdown
          role="group"
          aria-label="Button group with nested dropdown"
          placement="top-end"
        >
          <button
            type="button"
            class="btn primary_btn flex_center"
            ngbDropdownToggle
          >
            <span class="ph7-semibold">Download</span>
            <mat-icon class="ml-2">expand_more</mat-icon>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="d-flex" ngbDropdownItem (click)="DownloadPDF()">
              <span class="ph7-semibold">Download</span>
              <mat-icon class="ph6 ml-3">file_download</mat-icon>
            </button>
            <!-- <button class="d-flex" ngbDropdownItem (click)="printBill()">
              <span class="ph7-semibold">Print</span>
              <mat-icon class="ph6 ml-3">print</mat-icon>
            </button> -->
            <button
              id="print_btn"
              class="d-none"
              ngbDropdownItem
              [useExistingCss]="true"
              printSectionId="element-to-export"
              ngxPrint
            >
              <span class="ph7-semibold">Print hidden</span>
              <mat-icon class="ph6 ml-3">print</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="">
        <!-- <app-secondary-button
          buttonText="Create New Bill"
          size="small"
          (onButtonClick)="createNewBill()"
        ></app-secondary-button> -->
      </div>
    </div>
  </div>
</div>

<app-bill-pdf-download [bill_data]="bill_data"> </app-bill-pdf-download>

<!-- <button [useExistingCss]="true" printSectionId="element-to-export" ngxPrint>
  Print
</button> -->

<!-- <pre>{{ bill_data | json }}</pre> -->
