import { Component, OnInit } from '@angular/core';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { MultiDataSet, Label, Color } from 'ng2-charts';
import { ServerCode } from 'src/app/data/constants/server-code-constants';
import Swal from 'sweetalert2';
import { AdminRoutesPath } from 'src/app/data/constants/routes-constants';

var that: any;
@Component({
  selector: 'app-photeam-dashboard',
  templateUrl: './photeam-dashboard.component.html',
  styleUrls: ['./photeam-dashboard.component.css']
})
export class PhoteamDashboardComponent implements OnInit {

  routesPath = AdminRoutesPath;

  public lineChartLabels: Label[] = [];
  public lineChartData: ChartDataSets[] = [
    {
      label: "Photeams",
      lineTension: 0.3,
      backgroundColor: "rgba(78, 115, 223, 0.05)",
      borderColor: "rgba(78, 115, 223, 1)",
      pointRadius: 3,
      pointBackgroundColor: "rgba(78, 115, 223, 1)",
      pointBorderColor: "rgba(78, 115, 223, 1)",
      pointHoverRadius: 3,
      pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
      pointHoverBorderColor: "rgba(78, 115, 223, 1)",
      pointHitRadius: 10,
      pointBorderWidth: 2,
      data: [],
    },
  ];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    layout: {
      padding: {
        left: 10,
        right: 25,
        top: 25,
        bottom: 0
      }
    },
    scales: {
      xAxes: [{
        time: {
          unit: 'month'
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          maxTicksLimit: 7
        }
      }],
      yAxes: [{
        ticks: {
          maxTicksLimit: 5,
          padding: 10,
        },
        gridLines: {
          color: "rgb(234, 236, 244)",
          zeroLineColor: "rgb(234, 236, 244)",
          drawBorder: false,
          borderDash: [2],
          zeroLineBorderDash: [2]
        }
      }],
    },
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      titleMarginBottom: 10,
      titleFontColor: '#6e707e',
      titleFontSize: 14,
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      intersect: false,
      mode: 'index',
      caretPadding: 10,

    }
  };
  public lineChartColors: Color[] = [];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  // ----------------
  public doughnutChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      backgroundColor: "rgb(255,255,255)",
      bodyFontColor: "#858796",
      borderColor: '#dddfeb',
      borderWidth: 1,
      xPadding: 15,
      yPadding: 15,
      displayColors: false,
      caretPadding: 10,
    },
    legend: {
      display: false
    },
    cutoutPercentage: 80,
  };
  public doughnutChartLabels: Label[] = ['Total', 'Completed', 'Draft', 'Deleted'];
  public doughnutChartData: MultiDataSet = [
    [13, 9, 3, 1],

  ];
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartColors: Color[] = [
    {
      backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc', '#e74a3b'],
      hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf', '#be2617'],
      hoverBorderColor: "rgba(234, 236, 244, 1)",
    }
  ]


  // ------------------------

  constructor(
    private adminDataService: AdminDataService,
    private route: ActivatedRoute,
    private router: Router) {
    that = this
  }

  countData: any;
  photeamList: any;
  photeam_id: any;
  monthlyPhoteams:any;

  // onToggle(photeam_id: any, value: boolean, state: any,e:any){
  //   console.log(e);

  //   e.source.checked = true;
  //   console.log("toggle should not change if I click the cancel button")
  // }

  toggle_state(photeam_id: any, value: boolean, state: any,e:any) {
    console.log("passed_value",value);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will change the state!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, change it!',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.value) {

        this.adminDataService.changePhoteamState("change photeam state", photeam_id, value, state, this.successCB, this.errorCB);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
        e.source.checked = !e.source.checked;
        // console.log(e.source.checked );
      }
    })

  }

  fetchPhoteamData() {
    console.log("fecthing photeam data");
    this.adminDataService.getPhoteamData("fetch photeam data", this.successCB, this.errorCB);
  }

  dtOptions: DataTables.Settings = {};


  createLineGraph(){
    this.monthlyPhoteams?.forEach(function(item:any){
      that.lineChartLabels.push(item.month)
      that.lineChartData[0].data.push(item.total)
    });
  }

  createDoughnutGraph(){
    this.doughnutChartData[0][0]=this.countData.all_photeams.all;
    this.doughnutChartData[0][1]=this.countData.completed.all;
    this.doughnutChartData[0][2]=this.countData.draft.all;
    this.doughnutChartData[0][3]=this.countData.deleted.all;
  }

  ngOnInit(): void {
    this.fetchPhoteamData();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      processing: true,
      order: []
    };


  }

  upgradeMembership(user_id: any) {
    let url = `${AdminRoutesPath.UPGRADE_url}/${user_id}`
    this.router.navigateByUrl(url);
  }

  successCB(results: any) {
    if (results.api == 'fetch photeam data') {
      if (results.data != null && results.data != undefined) {

        that.countData = results.data.count;
        console.log("photeam_count", results.data.count);
        that.photeamList = results.data.photeamlist;
        console.log("photeam_count", results.data.photeamlist);
        that.monthlyPhoteams = results.data.monthlyPhoteams

        that.createLineGraph();
        that.createDoughnutGraph();
      }
    }

    if (results.api == 'change photeam state') {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log(results);
        Swal.fire({
          icon: 'success',
          title: 'Your changes has been saved',
          showConfirmButton: false,
          timer: 1500
        })


      }

    }
  }

  errorCB(error: any) {
    if (error != null && error != undefined) {
      console.log(error);
    }
  }
}
