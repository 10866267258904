import { Component, OnInit } from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';

import Swal from 'sweetalert2';

var that: any;
@Component({
  selector: 'app-products-dashboard',
  templateUrl: './products-dashboard.component.html',
  styleUrls: ['./products-dashboard.component.css']
})
export class ProductsDashboardComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private modalService: NgbModal
  ) { that = this; }


  productsList: any;
  productsData: any;
  formType = '';

  open(content: any) {
    this.modalService.open(content);
  }

  fetchProductList() {
    this.dataService.fetchProductList("fetch product list", this.successCB, this.errorCB);
  }

  editAction(item: any) {
    this.formType = "edit"
    this.productsData = item;

    let category = this.productsData.category;
    this.product_type = this.product_categories.find(item => item.category == category).type;
    // console.log(this.productsData);
  }

  product_categories: Array<any> = [
    { category: "PACKAGE", type: ["PREM"] },
    { category: "ADDONS", type: ["COIN", "FILE_TRANSFER_STORAGE", "BILLING_STORAGE"] },
  ]

  product_type: any[] = [];
  product_validity_type: any[] = ['years', 'months', 'weeks', 'days', 'hours', 'minutes'];

  changeCategory(event: any) {
    let category = event.target.value;
    this.product_type = this.product_categories.find(item => item.category == category).type;
  }

  toogle_validity_applicable(event: any) {
    if (!event.target.checked) {
      this.productsData.validity = null;
    } else {
      this.productsData.validity = {
        validity_type: "months",
        validity: 1
      }
    }
  }

  toogle_discount_applicable(event: any) {
    if (!event.target.checked) {
      this.productsData.discount = null;
    } else {
      this.productsData.discount = {
        discount_type: "percentage",
        value: 15
      }
    }
  }

  toogle_gst_applicable(event: any) {
    if (!event.target.checked) {
      this.productsData.gst = null;
    } else {
      this.productsData.gst = {
        gst_type: "percentage",
        value: 18
      }
    }
  }

  addAction() {
    this.formType = "add"
    this.productsData = {
      product_code: "",
      name: "",
      desc: "",
      purchased_count: null,
      max_uses_limit: null,
      max_uses_per_user: null,
      category: "",
      type: "",
      base_value: 0,
      discount: {
        discount_type: "percentage",
        value: 15
      },
      gst: {
        gst_type: "percentage",
        value: 18
      },
      final_value: 0,
      validity: {
        validity_type: "months",
        validity: 1
      },
      data: null,
      is_coupon_applicable: 0,
      is_active: 1,
    }
  }

  updateProduct() {

    if (this.productsData.product_code != "" && this.productsData.name != "" && this.productsData.base_value != "") {
      // console.log("new_values", this.languageData);
      Swal.fire({
        title: 'Are you sure?',
        // text: 'You will change the state!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {

          this.adminDataService.addProductData("update product data", this.productsData, this.successCB, this.errorCB);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )

          // console.log(e.source.checked );
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Some Feilds are emplty!',
        showConfirmButton: false,
        timer: 1500
      })
    }

  }


  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.fetchProductList();
    console.log(this.productsData);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      order: []
    };
  }


  successCB(results: any) {

    if (results.api == "fetch product list") {
      if (results.servercode == ServerCode.SUCCESS) {
        console.log("Hello2");
        console.log(results);


        if (results.data != null && results.data != undefined) {
          that.productsList = results.data;
          console.log("product ", results.data);

        }
        console.log(this.productsList);

      }
    }

    if (results.api == "update product data") {
      if (results.servercode == ServerCode.SUCCESS) {

        Swal.fire({
          icon: 'success',
          title: 'Changes saved!',
          showConfirmButton: false,
          timer: 1500
        })
        console.log("Hello");

        that.fetchProductList();
      }
    }



  }

  errorCB(error: any) {
    console.log(error);
  }

}
