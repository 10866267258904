import { TransactionDetailsComponent } from './components/dashboard/transactions-dashboard/transaction-details/transaction-details.component';
import { UserProfileComponent } from './components/dashboard/user-dashboard/user-profile/user-profile.component';
import { UserDashboardComponent } from './components/dashboard/user-dashboard/user-dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PhoteamDashboardComponent } from './components/dashboard/photeam-dashboard/photeam-dashboard.component';
import { PhoteamDetailComponent } from './components/dashboard/photeam-dashboard/photeam-detail/photeam-detail.component';
import { BookingDashboardComponent } from './components/dashboard/booking-dashboard/booking-dashboard.component';
import { BookingDetailsComponent } from './components/dashboard/booking-dashboard/booking-details/booking-details.component';
import { BlogDashboardComponent } from './components/dashboard/blog-dashboard/blog-dashboard.component';
import { CreateUserComponent } from './components/admin/create-user/create-user.component';
import { CouponsDashboardComponent } from './components/dashboard/coupons-dashboard/coupons-dashboard.component';
import { ProductsDashboardComponent } from './components/dashboard/products-dashboard/products-dashboard.component';
import { BillingDashboardComponent } from './components/dashboard/tools-dashboard/billing-dashboard/billing-dashboard.component';
import { BillingDetailsComponent } from './components/dashboard/tools-dashboard/billing-dashboard/billing-details/billing-details.component';
import { InvoiceDashboardComponent } from './components/dashboard/tools-dashboard/invoice-dashboard/invoice-dashboard.component';
import { InvoiceDetailsComponent } from './components/dashboard/tools-dashboard/invoice-dashboard/invoice-details/invoice-details.component';
import { QuotaionDashboardComponent } from './components/dashboard/tools-dashboard/quotaion-dashboard/quotaion-dashboard.component';
import { QuotaionDetailsComponent } from './components/dashboard/tools-dashboard/quotaion-dashboard/quotaion-details/quotaion-details.component';
import { UpgradeUserMembershipV2Component } from './components/dashboard/user-membership-dashboard/upgrade-user-membership-v2/upgrade-user-membership-v2.component';
import { UserMembershipDashboardComponent } from './components/dashboard/user-membership-dashboard/user-membership-dashboard.component';
import { LoginComponent } from './components/genric/login/login.component';
import { PageNotFoundComponent } from './components/genric/page-not-found/page-not-found.component';
import { MasterConstantsEnumListsComponent } from './components/masterData/master-constants-enum-lists/master-constants-enum-lists.component';
import { MasterGenreListsComponent } from './components/masterData/master-genre-lists/master-genre-lists.component';
import { MasterLanguageListsComponent } from './components/masterData/master-language-lists/master-language-lists.component';
import { MasterServicesListsComponent } from './components/masterData/master-services-lists/master-services-lists.component';
import { MasterServicesLocationsListsComponent } from './components/masterData/master-services-locations-lists/master-services-locations-lists.component';
import { AdminRoutesPath } from './data/constants/routes-constants';
import { AuthGuardService as AuthGuard } from './data/dataservice/auth-dataservice/auth-guard.service';
import { PhoteamPurchasesComponent } from './components/dashboard/photeam-dashboard/photeam-purchases/photeam-purchases.component';
import { PhoteamOverviewComponent } from './components/dashboard/photeam-dashboard/photeam-overview/photeam-overview.component';
import { TransactionsDashboardComponent } from './components/dashboard/transactions-dashboard/transactions-dashboard.component';

const routes: Routes = [
  { path: '', redirectTo: 'users', pathMatch: 'full' }, // redirect to `first-component`
  // { path: '', component: DefaultDashboardComponent,canActivate: [AuthGuard] },
  { path: AdminRoutesPath.USERS, component: UserDashboardComponent, canActivate: [AuthGuard] },
  { path: AdminRoutesPath.USER_DETAILS + '/:user_id', component: UserProfileComponent, canActivate: [AuthGuard] },

  { path: AdminRoutesPath.PHOTEAMS, component: PhoteamDashboardComponent, canActivate: [AuthGuard] },
  { path: AdminRoutesPath.PHOTEAM_OVERVIEW + '/:photeam_id', component: PhoteamOverviewComponent, canActivate: [AuthGuard] },
  { path: AdminRoutesPath.PHOTEAM_DETAILS + '/:photeam_id', component: PhoteamDetailComponent, canActivate: [AuthGuard] },
  { path: AdminRoutesPath.PHOTEAM_PURCHASES + '/:photeam_id', component: PhoteamPurchasesComponent, canActivate: [AuthGuard] },

  { path: AdminRoutesPath.BOOKINGS, component: BookingDashboardComponent, canActivate: [AuthGuard] },
  { path: AdminRoutesPath.BOOKING_DETAILS + '/:booking_id', component: BookingDetailsComponent, canActivate: [AuthGuard] },

  { path: AdminRoutesPath.TRANSACTIONS, component: TransactionsDashboardComponent, canActivate: [AuthGuard] },
  { path: AdminRoutesPath.TRANSACTION_DETAILS + '/:txn_src/:txn_id', component: TransactionDetailsComponent, canActivate: [AuthGuard] },

  { path: 'blogs', component: BlogDashboardComponent, canActivate: [AuthGuard] },

  { path: 'login', component: LoginComponent },
  { path: 'master-data/genre', component: MasterGenreListsComponent, canActivate: [AuthGuard] },
  { path: 'master-data/constants-enum', component: MasterConstantsEnumListsComponent, canActivate: [AuthGuard] },
  { path: 'master-data/languages', component: MasterLanguageListsComponent, canActivate: [AuthGuard] },
  { path: 'master-data/services', component: MasterServicesListsComponent, canActivate: [AuthGuard] },
  { path: 'master-data/locations', component: MasterServicesLocationsListsComponent, canActivate: [AuthGuard] },
  { path: 'add-user', component: CreateUserComponent, canActivate: [AuthGuard] },
  { path: 'membership', component: UserMembershipDashboardComponent, canActivate: [AuthGuard] },
  { path: AdminRoutesPath.UPGRADE, component: UpgradeUserMembershipV2Component, canActivate: [AuthGuard] },

  { path: 'quotations', component: QuotaionDashboardComponent, canActivate: [AuthGuard] },
  { path: 'quotations/:quotation_id', component: QuotaionDetailsComponent, canActivate: [AuthGuard] },
  { path: 'invoices', component: InvoiceDashboardComponent, canActivate: [AuthGuard] },
  { path: 'invoices/:invoice_id', component: InvoiceDetailsComponent, canActivate: [AuthGuard] },
  { path: 'bills', component: BillingDashboardComponent, canActivate: [AuthGuard] },
  { path: 'bills/:bill_id', component: BillingDetailsComponent, canActivate: [AuthGuard] },

  { path: 'products', component: ProductsDashboardComponent, canActivate: [AuthGuard] },
  { path: 'coupons', component: CouponsDashboardComponent, canActivate: [AuthGuard] },

  { path: '**', component: PageNotFoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
