<div class="container-fluid">
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Coupon Data</h1>
      <a
        href="#"
        class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
      >
    </div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">Coupons List</li>
      </ol>
    </nav>
  
    
    <!-- DataTales Example -->
    <div class="card shadow mb-4 mt-4">
      <div class="card-header py-3 d-flex justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">Coupons List</h6>
  
        <span class="btn btn-sm btn-info" (click)="open(content) ; addAction()">
          <i class="fas fa-plus fa-sm text-white-50"></i> Add New
        </span>
      </div>
      <div class="card-body">
        <div class="table-responsive" *ngIf="couponsList?.length>0">
          <table datatable [dtOptions]="dtOptions" id="myTable" class="table " id="dataTable" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th>Coupon Code</th>
                <th>Name</th>
                <th>Discount Type</th>
                <th>Discount Value</th>
                <th>Actions</th>
                <th>Is Active</th>
              </tr>
            </thead>
  
            <tbody>
              <tr *ngFor="let coupon of couponsList">
                <td>{{coupon.coupon_code }}</td>
                <td>{{coupon.name}}</td>
                <td>{{coupon.discount_value_type}}</td>
                <td>{{coupon.discount_value}}</td>
                <td>
                  <span title="Edit" (click)="open(content); editAction(coupon)" class="text-light btn-warning btn-circle">
                    <i class="fas fa-edit"></i>
                  </span>
                </td>
                <td>
                  <span *ngIf="coupon.is_active == 1; else falseBlock">True</span>
                  <ng-template #falseBlock>False</ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <pre>
          {{ productData | json }}
        </pre> -->
      </div>
    </div>
    
    <ng-template #content let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" *ngIf="formType=='edit'">Update Coupon</h4>
        <h4 class="modal-title" id="modal-basic-title" *ngIf="formType=='add'">Create Coupon</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body admin-form">
        <form *ngIf="couponData!=undefined">
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Coupon Code</label>
            <input class="form-control" [ngClass]="{'is-invalid': couponData.coupon_code=='' }" name="coupon_code"
              [(ngModel)]='couponData.coupon_code'>
            <div *ngIf="couponData.coupon_code==''" class="invalid-feedback">
              Required
            </div>
          </div>

          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Coupon Name</label>
            <input class="form-control " [ngClass]="{'is-invalid': couponData.name=='' }" name="name"
              [(ngModel)]='couponData.name'>
            <div *ngIf="couponData.name==''" class="invalid-feedback">
              Required
            </div>
          </div>

          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Description</label>
            <input class="form-control " [ngClass]="{'is-invalid': couponData.desc=='' }" name="desc"
              [(ngModel)]='couponData.desc'>
            <div *ngIf="couponData.desc==''" class="invalid-feedback">
              Required
            </div>
          </div>
          
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Max Uses Limit</label>
            <input type="number" class="form-control " [ngClass]="{'is-invalid': couponData.max_uses_limit<0 }"
              name="max_uses_limit" [(ngModel)]='couponData.max_uses_limit'>
            <div *ngIf="couponData.max_uses_limit<0" class="invalid-feedback">
              Positive number
            </div>
          </div>
          
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Max Uses Per User</label>
            <input type="number" class="form-control " [ngClass]="{'is-invalid': couponData.max_uses_per_user<0 }"
              name="max_uses_per_user" [(ngModel)]='couponData.max_uses_per_user'>
            <div *ngIf="couponData.max_uses_per_user<0" class="invalid-feedback">
              Positive number
            </div>
          </div>

          
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Applicable On</label>
            <input class="form-control " name="applicable_on" [(ngModel)]='couponData.applicable_on'>
          </div>
  
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Discount Type</label>
            <select class="form-control" [ngClass]="{'is-invalid': couponData.discount_value_type=='' }"
            name="discount_value_type" [(ngModel)]='couponData.discount_value_type'>
                <option value="">Choose a discount type</option>
                <option *ngFor="let discType of discTypes" value="{{discType}}">{{discType}}</option>
            </select>
            <div *ngIf="couponData.discount_value_type==''" class="invalid-feedback">
              Select an option
            </div>
          </div>
  
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Discount Value</label>
            <input type="number" class="form-control " [ngClass]="{'is-invalid': couponData.discount_value<0 }"
              name="discount_value" [(ngModel)]='couponData.discount_value'>
            <div *ngIf="couponData.discount_value<0" class="invalid-feedback">
              Positive number
            </div>
          </div>
  
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Validity Days</label>
            <input type="number" class="form-control " [ngClass]="{'is-invalid': couponData.validity_days<0 }"
              name="validity_days" [(ngModel)]='couponData.validity_days'>
            <div *ngIf="couponData.validity_days<0" class="invalid-feedback">
              Positive number
            </div>
          </div>
          
          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Is Active</label><br>
            <input type="radio" name="coupon_is_active" [value]=1 [(ngModel)]='couponData.is_active'><label for="">True</label>
            <input type="radio" name="coupon_is_active" [value]=0 [(ngModel)]='couponData.is_active' style="margin-left: 20%;"><label for="">False</label>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
        (click)="modal.close('Save click');updateCoupon() ">{{formType=='edit'?'update':"create"}}</button>
      </div>
    </ng-template>
  </div>
  
