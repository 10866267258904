import { Component, OnInit } from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ServerCode } from 'src/app/data/constants/server-code-constants';
import { GlobalConstants } from 'src/app/data/constants/global-constants';
import { AdminDataService } from 'src/app/data/dataservice/admin-dataservice/admin-data.service';
import { DataService } from 'src/app/data/dataservice/user-dataservice/data.service';

import Swal from 'sweetalert2';

var that: any;
@Component({
  selector: 'app-coupons-dashboard',
  templateUrl: './coupons-dashboard.component.html',
  styleUrls: ['./coupons-dashboard.component.css']
})
export class CouponsDashboardComponent implements OnInit {

  constructor(
    private dataService: DataService,
    private adminDataService: AdminDataService,
    private modalService: NgbModal
  ) { that = this; }

  discTypes = ["Numeric", "Percentage"]
  couponsList: any;
  couponData: any;
  formType = '';

  open(content: any) {
    this.modalService.open(content);
  }
  
  fetchCouponList() {
    this.dataService.fetchCouponList("fetch coupon list", this.successCB, this.errorCB);
  }

  editAction(item: any) {
    this.formType = "edit"
    this.couponData = item;
    console.log(this.couponData);
  }
  
  addAction() {
    this.formType = "add"
    this.couponData = {
      coupon_code: "",
      name: "",
      desc: "",
      max_uses_limit: 0,
      max_uses_per_user: 0,
      applicable_on: "item",
      discount_value_type: "",
      discount_value: -1,
      data: null,
      valid_from: '',
      valid_till: '',
      validity_days: null,
      is_active: false
    };
  }
  
  updateCoupon() {

    if (this.couponData.coupon_code != "" && this.couponData.name != "" && this.couponData.discount_value_type != "") {
      // console.log("new_values", this.languageData);
      Swal.fire({
        title: 'Are you sure?',
        // text: 'You will change the state!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        cancelButtonText: 'Cancel'
      }).then((result) => {
        if (result.value) {

          this.adminDataService.addCouponData("update coupon data", this.couponData, this.successCB, this.errorCB);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Swal.fire(
          //   'Cancelled',
          //   'Your imaginary file is safe :)',
          //   'error'
          // )

          // console.log(e.source.checked );
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Some Feilds are emplty!',
        showConfirmButton: false,
        timer: 1500
      })
    }

  }


  dtOptions: DataTables.Settings = {};

  ngOnInit(): void {
    this.fetchCouponList();
    console.log(this.couponData);    

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 15,
      processing: true,
      order: []
    };
  }

  
  successCB(results:any) {

    if (results.api == "fetch coupon list") {
      if (results.servercode == ServerCode.SUCCESS) {

        if (results.data != null && results.data != undefined) {
          that.couponsList = results.data;
          console.log("coupon ",results.data);

        }
      }
    }

    if (results.api == "update coupon data") {
      if (results.servercode == ServerCode.SUCCESS) {

        Swal.fire({
          icon: 'success',
          title: 'Changes saved!',
          showConfirmButton: false,
          timer: 1500
        })

        that.fetchCouponList();
      }
    }



  }

  errorCB(error:any) {
    console.log(error);
  }


}
