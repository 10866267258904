<div class="tw-py-8">
  <div class="row tw-justify-center">
    <div class="col-md-2">
      <div class="border rounded tw-bg-white tw-text-center py-2">
        <div>Studio Plan</div>
        <div>{{ data.deliverable.active_plans.studio_plan }}</div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="border rounded tw-bg-white tw-text-center py-2">
        <div>Cloud Plan</div>
        <div>{{ data.deliverable.active_plans.cloud_plan }}</div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="border rounded tw-bg-white tw-text-center py-2">
        <div>Billing Plan</div>
        <div>{{ data.deliverable.active_plans.billing_plan }}</div>
      </div>
    </div>
  </div>
  <div class="d-flex align-item-center tw-justify-center">
    <div
      class="tw-w-fit tw-text-blue-800 tw-cursor-pointer"
      (click)="viewJSON(data)"
    >
      View Deliverables JSON
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Studio Plan</div>
      <div>
        <div>studio_creation</div>
        <pre>{{ data.deliverable.studio_creation | json }}</pre>
      </div>

      <div>
        <div>booking_mgmt</div>
        <pre>{{ data.deliverable.booking_mgmt | json }}</pre>
      </div>

      <div>
        <div>invoice</div>
        <pre>{{ data.deliverable.invoice | json }}</pre>
      </div>

      <div>
        <div>quotation</div>
        <pre>{{ data.deliverable.quotation | json }}</pre>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Cloud Plan</div>
      <div>
        <div>file_transfer</div>
        <pre>{{ data.deliverable.file_transfer | json }}</pre>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="border rounded tw-bg-orange-100 p-3">
      <div>Billing Plan</div>
      <div>
        <div>studio_billing</div>
        <pre>{{ data.deliverable.studio_billing | json }}</pre>
      </div>
    </div>
  </div>
</div>

<ng-template #json_modal let-modal>
  <div class="container p-3">
    <div class="d-flex justify-content-between mb-3">
      <div>
        <div class="ph5-bold ptc1">View JSON</div>
        <!-- <div class="ph7 ptc5">
          You have not created any Studio yet. Please create your studio.
        </div> -->
      </div>
      <div>
        <mat-icon class="ph4 ptc1 cursor_pointer" (click)="onModalClose()"
          >close</mat-icon
        >
      </div>
    </div>
    <ng-container>
      <pre>{{ view_json | json }}</pre>
    </ng-container>
  </div>
</ng-template>
