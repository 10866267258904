import { BillPdfDownloadComponent } from '../bill-pdf-download/bill-pdf-download.component';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GlobalConstants } from 'src/app/data/constants/global-constants';

var that;

@Component({
  selector: 'app-bill-pdf-preview',
  templateUrl: './bill-pdf-preview.component.html',
  styleUrls: ['./bill-pdf-preview.component.css']
})
export class BillPdfPreviewComponent implements OnInit {

  @Input() bill_data: any;
  @Output("onClose") onClose: EventEmitter<any> = new EventEmitter();
  @ViewChild(BillPdfDownloadComponent) private billPdfComponent: BillPdfDownloadComponent;
  loader = false;

  DO_CDN_URL = GlobalConstants.DO_CDN_URL;

  constructor(private toastr: ToastrService, private router: Router,) {
    that = this;
  }

  activeformat = { gst: 'Percent', discount: 'Value' };
  discountFieldValue: number = 0;
  gstFieldValue: number = 0;

  ngOnInit(): void {

  }

  createNewBill() {
    this.closeModal();
    this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl('/create-studio-bills/'));
  }

  closeModal() {
    this.onClose.emit();
  }

  DownloadPDF() {
    this.billPdfComponent.onDownloadPDF();
  }

  printBill() {
    let print_btn = document.getElementById('print_btn');
    print_btn.click();
  }



}
