<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Products Data</h1>
    <a
      href="#"
      class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
      ><i class="fas fa-download fa-sm text-white-50"></i> Generate Report</a
    >
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Products List</li>
    </ol>
  </nav>

  <!-- DataTales Example -->
  <div class="card shadow mb-4 mt-4">
    <div class="card-header py-3 d-flex justify-content-between">
      <h6 class="m-0 font-weight-bold text-primary">Products List</h6>

      <span class="btn btn-sm btn-info" (click)="open(content); addAction()">
        <i class="fas fa-plus fa-sm text-white-50"></i> Add New
      </span>
    </div>
    <div class="card-body">
      <div class="table-responsive" *ngIf="productsList?.length > 0">
        <table
          datatable
          [dtOptions]="dtOptions"
          id="myTable"
          class="table"
          id="dataTable"
          width="100%"
          cellspacing="0"
        >
          <thead>
            <tr>
              <th>Product Code</th>
              <th>Product Type</th>
              <th>Name</th>
              <th>Base Price</th>
              <th>Final Price</th>
              <th>Actions</th>
              <th>Category</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let product of productsList">
              <td>{{ product.product_code }}</td>
              <td>{{ product.type }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.base_value }}</td>
              <td>{{ product.final_value }}</td>
              <td>
                <span
                  title="Edit"
                  (click)="open(content); editAction(product)"
                  class="text-light btn-warning btn-circle"
                >
                  <i class="fas fa-edit"></i>
                </span>
              </td>
              <td>{{ product.category }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <pre>
        {{ productData | json }}
      </pre> -->
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType == 'edit'">
        Update Product
      </h4>
      <h4 class="modal-title" id="modal-basic-title" *ngIf="formType == 'add'">
        Create Product
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body admin-form">
      <form *ngIf="productsData != undefined">
        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Product Code</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.product_code == '' }"
            name="product_code"
            [(ngModel)]="productsData.product_code"
          />
          <div *ngIf="productsData.product_code == ''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Product Name</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.name == '' }"
            name="product_name"
            [(ngModel)]="productsData.name"
          />
          <div *ngIf="productsData.name == ''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Description</label>
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.desc == '' }"
            name="product_desc"
            [(ngModel)]="productsData.desc"
          />
          <div *ngIf="productsData.desc == ''" class="invalid-feedback">
            Required
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800"
            >Max Uses Limit</label
          >
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.max_uses_limit < 0 }"
            name="product_max_uses_limit"
            type="number"
            [(ngModel)]="productsData.max_uses_limit"
          />
          <div *ngIf="productsData.max_uses_limit < 0" class="invalid-feedback">
            Positive Number
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800"
            >Max Uses Per User</label
          >
          <input
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.max_uses_per_user < 0 }"
            name="product_max_uses_per_user"
            type="number"
            [(ngModel)]="productsData.max_uses_per_user"
          />
          <div
            *ngIf="productsData.max_uses_per_user < 0"
            class="invalid-feedback"
          >
            Positive Number
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Category</label>
          <select
            (change)="changeCategory($event)"
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.category == '' }"
            name="category"
            [(ngModel)]="productsData.category"
          >
            <option value="">Choose a Category</option>
            <option
              *ngFor="let item of product_categories"
              value="{{ item.category }}"
            >
              {{ item.category }}
            </option>
          </select>
          <div *ngIf="productsData.category == ''" class="invalid-feedback">
            Select an option
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Type</label>
          <select
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.type == '' }"
            name="type"
            [(ngModel)]="productsData.type"
          >
            <option value="">Choose a Type</option>
            <option *ngFor="let item of product_type" [value]="item">
              {{ item }}
            </option>
          </select>
          <div *ngIf="productsData.type == ''" class="invalid-feedback">
            Select an option
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Base Price</label>
          <input
            type="number"
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.base_value < 0 }"
            name="base_value"
            [(ngModel)]="productsData.base_value"
          />
          <div *ngIf="productsData.base_value < 0" class="invalid-feedback">
            Positive number
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <div class="form-group" *ngIf="productsData.discount">
            <label class="h6 font-weight-bold text-gray-800"
              >Discount Value</label
            >
            <input
              type="number"
              class="form-control"
              [ngClass]="{ 'is-invalid': productsData.discount.value < 0 }"
              name="discount_value"
              [(ngModel)]="productsData.discount.value"
            />
          </div>
          <div class="form-group" *ngIf="productsData.discount">
            <div>
              <input
                type="radio"
                name="discount_type"
                [value]="'percentage'"
                [(ngModel)]="productsData.discount.discount_type"
              /><label for="">Percentage</label>
            </div>
            <div>
              <input
                type="radio"
                name="discount_type"
                [value]="'amount'"
                [(ngModel)]="productsData.discount.discount_type"
              /><label for="">Amount</label>
            </div>
          </div>

          <div class="form-group text-center">
            <label class="h6 font-weight-bold text-gray-800">Discount</label>
            <input
              type="checkbox"
              class="form-control"
              name="discount_null"
              [checked]="productsData.discount != null"
              (change)="toogle_discount_applicable($event)"
            />
            <span>{{
              productsData.discount != null ? "Exist" : "Not Exist"
            }}</span>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <div class="form-group" *ngIf="productsData.gst">
            <label class="h6 font-weight-bold text-gray-800">GST</label>
            <input
              type="number"
              class="form-control"
              [ngClass]="{ 'is-invalid': productsData.gst.value < 0 }"
              name="gst_value"
              [(ngModel)]="productsData.gst.value"
            />
          </div>

          <div class="form-group" *ngIf="productsData.gst">
            <div>
              <input
                type="radio"
                name="gst_type"
                [value]="'percentage'"
                [(ngModel)]="productsData.gst.gst_type"
              /><label for="">Percentage</label>
            </div>
            <div>
              <input
                type="radio"
                name="gst_type"
                [value]="'amount'"
                [(ngModel)]="productsData.gst.gst_type"
              /><label for="">Amount</label>
            </div>
          </div>

          <div class="form-group text-center">
            <label class="h6 font-weight-bold text-gray-800">GST</label>
            <input
              type="checkbox"
              class="form-control"
              name="gst_null"
              [checked]="productsData.gst != null"
              (change)="toogle_gst_applicable($event)"
            />
            <span>{{ productsData.gst != null ? "Exist" : "Not Exist" }}</span>
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Final Price</label>
          <input
            type="number"
            class="form-control"
            [ngClass]="{ 'is-invalid': productsData.final_value < 0 }"
            name="final_value"
            [(ngModel)]="productsData.final_value"
          />
          <div *ngIf="productsData.final_value < 0" class="invalid-feedback">
            Positive number
          </div>
        </div>

        <div class="d-flex">
          <div class="form-group" *ngIf="productsData.validity">
            <label class="h6 font-weight-bold text-gray-800">Duration </label>
            <input
              type="number"
              class="form-control"
              [ngClass]="{ 'is-invalid': productsData.validity.validity < 0 }"
              name="validity_days"
              [(ngModel)]="productsData.validity.validity"
            />
            <div
              *ngIf="productsData.validity.validity < 0"
              class="invalid-feedback"
            >
              Positive number
            </div>
          </div>

          <div class="form-group" *ngIf="productsData.validity">
            <label class="h6 font-weight-bold text-gray-800">Type</label>
            <select
              class="form-control"
              [ngClass]="{
                'is-invalid': productsData.validity.validity_type == ''
              }"
              name="type"
              [(ngModel)]="productsData.validity.validity_type"
            >
              <option value="">Choose a Type</option>
              <option
                *ngFor="let item of product_validity_type"
                value="{{ item }}"
              >
                {{ item }}
              </option>
            </select>
            <div
              *ngIf="productsData.validity.validity_type == ''"
              class="invalid-feedback"
            >
              Select an option
            </div>
          </div>

          <div class="form-group">
            <label class="h6 font-weight-bold text-gray-800">Validity</label>
            <input
              type="checkbox"
              class="form-control"
              name="validity_null"
              [checked]="productsData.validity != null"
              (change)="toogle_validity_applicable($event)"
            />
            <span>{{
              productsData.validity != null ? "Limited" : "No Limit"
            }}</span>
          </div>
        </div>

        <div class="form-group">
          <label class="h6 font-weight-bold text-gray-800">Is Active</label
          ><br />
          <input
            type="radio"
            name="product_is_active"
            [value]="1"
            [(ngModel)]="productsData.is_active"
          /><label for="">True</label>
          <input
            type="radio"
            name="product_is_active"
            [value]="0"
            [(ngModel)]="productsData.is_active"
            style="margin-left: 20%"
          /><label for="">False</label>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.close('Save click'); updateProduct()"
      >
        {{ formType == "edit" ? "update" : "create" }}
      </button>
    </div>
  </ng-template>
</div>
