import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { DataTablesModule } from "angular-datatables";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CKEditorModule } from 'ckeditor4-angular';
import { ToastrModule } from 'ngx-toastr';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { NgxPrintModule } from 'ngx-print';

import { AuthInterceptor } from './data/dataservice/auth-dataservice/auth-interceptor';
import { SpinnerInterceptor } from './data/spinner-interceptor';

import { AdminAuthService } from './data/dataservice/auth-dataservice/adminAuth.service';
import { AuthGuardService as AuthGuard } from './data/dataservice/auth-dataservice/auth-guard.service';
import { DataService } from './data/dataservice/user-dataservice/data.service';
import { AdminDataService } from './data/dataservice/admin-dataservice/admin-data.service';

import { FooterComponent } from './components/genric/footer/footer.component';
import { NavBarComponent } from './components/genric/nav-bar/nav-bar.component';
import { SideBarComponent } from './components/genric/side-bar/side-bar.component';
import { DefaultDashboardComponent } from './components/dashboard/default-dashboard/default-dashboard.component';
import { PhoteamDashboardComponent } from './components/dashboard/photeam-dashboard/photeam-dashboard.component';
import { UserDashboardComponent } from './components/dashboard/user-dashboard/user-dashboard.component';
import { LoginComponent } from './components/genric/login/login.component';
import { UserProfileComponent } from './components/dashboard/user-dashboard/user-profile/user-profile.component';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhoteamDetailComponent } from './components/dashboard/photeam-dashboard/photeam-detail/photeam-detail.component';
import { BlogDashboardComponent } from './components/dashboard/blog-dashboard/blog-dashboard.component';
import { MasterGenreListsComponent } from './components/masterData/master-genre-lists/master-genre-lists.component';
import { MasterConstantsEnumListsComponent } from './components/masterData/master-constants-enum-lists/master-constants-enum-lists.component';
import { MasterLanguageListsComponent } from './components/masterData/master-language-lists/master-language-lists.component';
import { MasterServicesListsComponent } from './components/masterData/master-services-lists/master-services-lists.component';
import { MasterServicesLocationsListsComponent } from './components/masterData/master-services-locations-lists/master-services-locations-lists.component';
import { SpinnerComponent } from './components/genric/spinner/spinner.component';
import { PageNotFoundComponent } from './components/genric/page-not-found/page-not-found.component';
import { CreateUserComponent } from './components/admin/create-user/create-user.component';
import { AdminLogsComponent } from './components/admin/admin-logs/admin-logs.component';
import { BookingDashboardComponent } from './components/dashboard/booking-dashboard/booking-dashboard.component';
import { BookingDetailsComponent } from './components/dashboard/booking-dashboard/booking-details/booking-details.component';
import { BookingPkgDetailsComponent } from './components/dashboard/booking-dashboard/booking-details/booking-pkg-details/booking-pkg-details.component';
import { UserMembershipDashboardComponent } from './components/dashboard/user-membership-dashboard/user-membership-dashboard.component';
import { UpgradeUserMembershipComponent } from './components/dashboard/user-membership-dashboard/upgrade-user-membership/upgrade-user-membership.component';
import { PaymentsDataService } from './data/dataservice/payments-dataservice/payments-data.service';
import { ProductsDashboardComponent } from './components/dashboard/products-dashboard/products-dashboard.component';
import { CouponsDashboardComponent } from './components/dashboard/coupons-dashboard/coupons-dashboard.component';
import { UpgradeUserMembershipV2Component } from './components/dashboard/user-membership-dashboard/upgrade-user-membership-v2/upgrade-user-membership-v2.component';
import { QuotationPdfPreviewComponent } from './components/genric/tools/quotation/quotation-pdf-preview/quotation-pdf-preview.component';
import { QuotationPdfDownloadComponent } from './components/genric/tools/quotation/quotation-pdf-download/quotation-pdf-download.component';
import { InvoicePdfPreviewComponent } from './components/genric/tools/invoice/invoice-pdf-preview/invoice-pdf-preview.component';
import { InvoicePdfDownloadComponent } from './components/genric/tools/invoice/invoice-pdf-download/invoice-pdf-download.component';
import { QuotaionDashboardComponent } from './components/dashboard/tools-dashboard/quotaion-dashboard/quotaion-dashboard.component';
import { QuotaionDetailsComponent } from './components/dashboard/tools-dashboard/quotaion-dashboard/quotaion-details/quotaion-details.component';
import { InvoiceDashboardComponent } from './components/dashboard/tools-dashboard/invoice-dashboard/invoice-dashboard.component';
import { BillingDashboardComponent } from './components/dashboard/tools-dashboard/billing-dashboard/billing-dashboard.component';
import { BillingDetailsComponent } from './components/dashboard/tools-dashboard/billing-dashboard/billing-details/billing-details.component';
import { InvoiceDetailsComponent } from './components/dashboard/tools-dashboard/invoice-dashboard/invoice-details/invoice-details.component';
import { BillPdfDownloadComponent } from './components/genric/tools/billing/bill-pdf-download/bill-pdf-download.component';
import { BillPdfPreviewComponent } from './components/genric/tools/billing/bill-pdf-preview/bill-pdf-preview.component';
import { AdminRoutesPath } from './data/constants/routes-constants';
import { PhoteamSelectionDropdownComponent } from './components/genric/photeam/photeam-selection-dropdown/photeam-selection-dropdown.component';
import { ResponsiveService } from './data/services/responsive.service';
import { PhoteamPurchasesComponent } from './components/dashboard/photeam-dashboard/photeam-purchases/photeam-purchases.component';
import { PhoteamOverviewComponent } from './components/dashboard/photeam-dashboard/photeam-overview/photeam-overview.component';
import { PhoteamDeliverablesComponent } from './components/dashboard/photeam-dashboard/photeam-deliverables/photeam-deliverables.component';
import { TransactionsDashboardComponent } from './components/dashboard/transactions-dashboard/transactions-dashboard.component';
import { TransactionDetailsComponent } from './components/dashboard/transactions-dashboard/transaction-details/transaction-details.component';


// const appRoutes: Routes = [
//   { path: '', redirectTo: 'users', pathMatch: 'full' }, // redirect to `first-component`
//   // { path: '', component: DefaultDashboardComponent,canActivate: [AuthGuard] },
//   { path: 'photeams', component: PhoteamDashboardComponent, canActivate: [AuthGuard] },
//   { path: 'photeams/:photeam_id', component: PhoteamDetailComponent, canActivate: [AuthGuard] },
//   { path: 'users', component: UserDashboardComponent, canActivate: [AuthGuard] },
//   { path: 'users/:user_id', component: UserProfileComponent, canActivate: [AuthGuard] },
//   { path: 'bookings', component: BookingDashboardComponent, canActivate: [AuthGuard] },
//   { path: 'bookings/:booking_id', component: BookingDetailsComponent, canActivate: [AuthGuard] },
//   { path: 'blogs', component: BlogDashboardComponent, canActivate: [AuthGuard] },

//   { path: 'login', component: LoginComponent },
//   { path: 'master-data/genre', component: MasterGenreListsComponent, canActivate: [AuthGuard] },
//   { path: 'master-data/constants-enum', component: MasterConstantsEnumListsComponent, canActivate: [AuthGuard] },
//   { path: 'master-data/languages', component: MasterLanguageListsComponent, canActivate: [AuthGuard] },
//   { path: 'master-data/services', component: MasterServicesListsComponent, canActivate: [AuthGuard] },
//   { path: 'master-data/locations', component: MasterServicesLocationsListsComponent, canActivate: [AuthGuard] },
//   { path: 'add-user', component: CreateUserComponent, canActivate: [AuthGuard] },
//   { path: 'membership', component: UserMembershipDashboardComponent, canActivate: [AuthGuard] },
//   { path: AdminRoutesPath.UPGRADE, component: UpgradeUserMembershipV2Component, canActivate: [AuthGuard] },

//   { path: 'quotations', component: QuotaionDashboardComponent, canActivate: [AuthGuard] },
//   { path: 'quotations/:quotation_id', component: QuotaionDetailsComponent, canActivate: [AuthGuard] },
//   { path: 'invoices', component: InvoiceDashboardComponent, canActivate: [AuthGuard] },
//   { path: 'invoices/:invoice_id', component: InvoiceDetailsComponent, canActivate: [AuthGuard] },
//   { path: 'bills', component: BillingDashboardComponent, canActivate: [AuthGuard] },
//   { path: 'bills/:bill_id', component: BillingDetailsComponent, canActivate: [AuthGuard] },

//   { path: 'products', component: ProductsDashboardComponent, canActivate: [AuthGuard] },
//   { path: 'coupons', component: CouponsDashboardComponent, canActivate: [AuthGuard] },

//   { path: '**', component: PageNotFoundComponent },

// ];

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavBarComponent,
    SideBarComponent,
    DefaultDashboardComponent,
    PhoteamDashboardComponent,
    UserDashboardComponent,
    LoginComponent,
    UserProfileComponent,
    PhoteamDetailComponent,
    BlogDashboardComponent,
    MasterGenreListsComponent,
    MasterConstantsEnumListsComponent,
    MasterLanguageListsComponent,
    MasterServicesListsComponent,
    SpinnerComponent,
    MasterServicesLocationsListsComponent,
    PageNotFoundComponent,
    CreateUserComponent,
    AdminLogsComponent,
    BookingDashboardComponent,
    BookingDetailsComponent,
    BookingPkgDetailsComponent,
    UserMembershipDashboardComponent,
    UpgradeUserMembershipComponent,
    ProductsDashboardComponent,
    CouponsDashboardComponent,
    UpgradeUserMembershipV2Component,
    QuotaionDashboardComponent,
    QuotaionDetailsComponent,
    QuotationPdfPreviewComponent,
    QuotationPdfDownloadComponent,
    InvoiceDashboardComponent,
    InvoiceDetailsComponent,
    InvoicePdfPreviewComponent,
    InvoicePdfDownloadComponent,
    BillingDashboardComponent,
    BillingDetailsComponent,
    BillPdfDownloadComponent,
    BillPdfPreviewComponent,
    PhoteamSelectionDropdownComponent,
    PhoteamPurchasesComponent,
    PhoteamOverviewComponent,
    PhoteamDeliverablesComponent,
    TransactionsDashboardComponent,
    TransactionDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // RouterModule.forRoot(appRoutes),
    HttpClientModule,
    FormsModule,
    DataTablesModule,
    CKEditorModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          return localStorage.getItem('token');
        }
      }
    }),
    NgbModule,
    ChartsModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    CommonModule,
    MatChipsModule,
    MatIconModule,
    ToastrModule.forRoot(),
    NgxPrintModule
  ],
  providers: [
    DataService,
    ResponsiveService,
    AdminDataService,
    AdminAuthService,
    PaymentsDataService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
