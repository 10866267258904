export class AdminRoutesPath {

  // --- main landing pages ---
  public static INDEX: string = "/";

  public static USERS: string = "users";
  public static USER_DETAILS: string = "user-details";

  public static PHOTEAMS: string = "photeams";
  public static PHOTEAM_OVERVIEW: string = "photeam-overview";
  public static PHOTEAM_DETAILS: string = "photeam-details";
  public static PHOTEAM_PURCHASES: string = "photeam-purchases";

  public static BOOKINGS: string = "bookings";
  public static BOOKING_DETAILS: string = 'booking-details';

  public static TRANSACTIONS: string = "transactions";
  public static TRANSACTION_DETAILS: string = "transaction-details";

  public static UPGRADE: string = "purchase/:user_id";
  public static UPGRADE_url: string = "purchase";

}
